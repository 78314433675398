:root {
  --blue: #0186b1;
  --pale-blue: #c4d2e1;
  --purple: #9d0a6d;
  --dark-purple: #7e0958;
  --red: #db5062;
  --yellow: #ffc107;
  --black: #2b2b2b;
  --grey: #666;
  --mid-grey: #828282;
  --pale-grey: #e5e5e5;
  --light: #f6f6f6;
  --menu-dark: rgb(36, 36, 36);
  --menu-active-dark: rgb(48, 48, 48);
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  /* Fix heavey fonts on Mac */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.clickable {
  cursor: pointer;
  fill: "transparent";
}

.clickable:hover {
  fill: "#ff0000";
}

.motion-timeline-label {
  fill: var(--menu-dark);
}

.door {
  stroke: var(--mid-grey);
  stroke-width: 2px;
}

.door-open {
  fill: white;
}

.door-closed {
  fill: var(--mid-grey);
}
